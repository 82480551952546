@font-face {
  font-family: 'Rooberto';
  font-display: fallback;
  src: url('./fonts/Roobert-Regular.otf') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Rooberto';
  font-display: fallback;
  src: url('./fonts/Roobert-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Rooberto';
  font-display: fallback;
  src: url('./fonts/Roobert-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

html {
  --text-ff: Rooberto, 'Segoe UI', Arial;

  --color-text-light: rgba(255, 255, 255, 0.4);

  --label-fs: 12px;
  --input-color: rgb(20, 20, 20, 50%);
  --input-border-color: white;
  --input-fs: 16px;
  --input-placeholder-color: rgba(255, 255, 255, 0.5);
  --input-placeholder-fs: 16px;
  --input-height: 52px;
  --textarea-lh: 1.2;
  --textarea-fs: 16px;

  --button-primary-color: #141414;
  --button-fs: 16px;

  --app-padding-left: 101%;
}

body {
  line-height: 1.6;
  font-family: var(--text-ff);
}

.Hero-background {
  overflow-x: hidden;
  position: relative;
  z-index: 1;
}

body {
  background-color: black;
}

.BackgroundImage {
  background-image: url(./herobg-d.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: -400% center;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.App {
  min-height: 100vh;
}

.Header {
  height: 56px;
  padding-left: 18px;
  padding-right: 18px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.Header-container {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: space-between;

  @media (min-width: 768px) {
    border-bottom: 1px solid rgba(80, 80, 80, 0.4);
    height: 66px;
  }
}

.LogoIcon {
  width: 30px;
}

.LogoWordmark {
  width: 48px;
}

.FormGroup-wrapper {
  position: relative;
}

.Button {
  background-color: transparent;
  display: inline-block;
  border: 0;
  padding: 0;
  margin: 0;
  font-size: 16px;
  text-transform: none;
}

.Button.is-primary {
  border-radius: 99999px;
  background-color: white;
  font-size: var(--button-fs);
  line-height: 36px;
  padding-left: 36px;
  padding-right: 36px;
}

.Input,
.TextArea {
  background-color: rgba(20, 20, 20, 0.5);
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  color: white;
  display: block;
  height: var(--input-height);
  font-size: var(--input-fs);
  line-height: var(--input-height);
  outline: 0;
  padding-left: 12px;
  padding-right: 50px;
  resize: none;
  width: 100%;
}

.TextArea {
  padding-right: 12px;
  line-height: 1.6;
  padding-top: 11px;
  padding-bottom: 2px;
  padding-right: 50px;
}

.Input::placeholder {
  color: var(--input-placeholder-color);
  font-size: var(--input-placeholder-fs);
}

.Label {
  font-size: var(--label-fs);
  color: white;
  padding-bottom: 15px;
  display: inline-block;
}

.FormGroup {
  padding-bottom: 9px;
  position: relative;
}

.InputValidCheckmark {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  opacity: 0;
  transition: opacity 100ms;

  .Input:valid + &,
  .TextArea:valid + & {
    opacity: 1;
  }
}

.ContentContainer {
  padding-left: 14px;
  padding-right: 14px;

  @media (min-width: 500px) {
    margin-left: auto;
    margin-right: auto;

    &.is-hero {
      max-width: 500px;
      // padding-top: 100px;
    }

    &.is-form {
      max-width: 440px;
    }
  }

  @media (min-width: 500px) and (min-height: 800px) {
    // padding-top: 200px;
  }

  @media (min-width: 768px) {
    &.is-hero {
      max-width: 920px;
      padding-right: 40px;


    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
      // padding-top: 200px;
      // padding-top: 270px;
    }

    &.is-form {
      padding-top: 250px;
      // padding-top: 380px;
    }
  }

  @media (min-width: 768px)  and (min-height: 800px) {
    &.is-hero {
      // padding-top: 200px;
    }
  }

  @media (min-width: 768px) {
    margin-left: 37%;
  }

  @media (min-width: 1000px) {
    &.is-hero {
      // padding-top: 200px;
    }
    
    &.is-form {
      padding-top: 280px;
      // padding-top: 400px;
    }
  }

  @media (min-width: 1000px) {
    &.is-form {
      padding-top: 140px;
    }
  }

  @media (min-width: 1200px) {
    &.is-form {
      padding-top: 250px;
    }
  }

  @media (min-width: 1499px) {
    margin-left: 40%;

    &.is-form {
      padding-top: 250px;
    }
  }
}

.SplashForm {
  width: 100%;
}

.SplashForm-title {
  color: rgba(255, 255, 255, 0.4);
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 25px;
  margin-top: 40px;

  @media (min-width: 768px) {
    font-size: 40px;
    margin-bottom: 45px;
    margin-top: 0;
  }
}

.SplashForm-submit {
  margin-top: 40px;

  @media (min-width: 1025px) {
    margin-top: 84px;
  }
}

.Hero {
  padding-bottom: 40px;
  min-height: 100vh;

  @media (min-width: 500px) {
    min-height: 70vh;
  }

  @media (min-width: 768px) {
    display: block;
    min-height: calc(100vh);
  }
}

.Hero-background {
  flex: 1;
  position: relative;
  z-index: -1;

  @media (min-width: 768px) {
    flex: initial;
    position: absolute;
    height: 100%;
    left: 0;
    width: 100%;
  }
}

.Hero-image {
  display: block;
  pointer-events: none;
  padding-top: 90px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 350px;
  z-index: 1;

  @media (min-width: 500px) {
    padding-top: 80px;
  }

  @media (min-height: 800px) {
    padding-top: 160px;
  }

  @media (min-width: 768px) {
    height: 710px;
    max-width: initial;
    object-fit: contain;
    object-position: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding-top: 0;
    width: 100%;
    height: 100%;
  }
}

.Hero-button {
  margin-top: 30px;
}

.Hero-title {
  color: white;
  font-size: 26px;
  line-height: 1;
  padding-top: 26px;
  padding-bottom: 32px;
  font-weight: 500;

  @media (min-width: 400px) {
    font-size: 32px;
    // padding-top: 56px;
  }

  @media (min-width: 768px) {
    font-size: 64px;
    // padding-top: 56px;
    padding-bottom: 100px;
  }

  @media (min-width: 1599px) {
    padding-bottom: 100px;
  }
}

.Hero-lead {
  color: var(--color-text-light);
  font-size: 26px;
  font-weight: 500;
  line-height: 1;

  @media (min-width: 400px) {
    font-size: 32px;
  }

  @media (min-width: 768px) {
    font-size: 40px;
  }
}

.Hero-info {
  &.is-desktop {
    display: none;
  }

  &.is-desktop {
    @media (min-width: 768px) {
      display: block;
    }
  }

  &.is-mobile {
    display: block;
  }

  &.is-mobile {
    @media (min-width: 768px) {
      display: none;
    }
  }
}

.SplashForm-success,
.splashForm-error {
  color: white;
  font-size: 24px;
  line-height: 1.1;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 40px;
  }
}

.SplashForm-success {
  @media (min-width: 768px) {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 37%;
  }

  @media (min-width: 1499px) {
    margin-left: 40%;
  }
}

.SplashForm-error {
  color: #f5a0a0;
  padding-bottom: 10px;
  padding-top: 20px;
}
